<template>
  <v-app
      id="dashboard-view"
      dark>

    <core-toolbar />
    <core-drawer />

    <v-main>
        <router-view />
    </v-main>

  </v-app>
</template>

<script>
export default {
  name: 'DashboardView',
  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  mounted() {
    this.$store.state.app.section='dashboard';
  }

}

</script>

<style>

</style>
